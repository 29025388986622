import React, { useState } from "react";
import styled from "styled-components";
import { Nav } from "./Nav";

const StyledBurger = styled.div<{ open?: boolean }>`
  width: 2rem;
  height: 1.7rem;
  position: relative;
  top: 2px;
  right: -10px;
  z-index: 20;
  display: none;
  @media (max-width: 1020px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    margin-bottom: 10px;
  }
  div {
    width: 1.8rem;
    height: 0.2rem;
    background-color: #0c2074;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const Menu = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <Nav open={open} />
    </>
  );
};

import React, { useState, useEffect, forwardRef } from "react";
import styled, { IStyledComponent } from "styled-components";
import { Input, TextField } from "./Input";
import { FieldMessage } from "Components/RegistrationForm/Fields/FieldMessage";

const NO_RESULTS_TEXT = "No options found...";

type AutocompleteProps = {
  onChange: (v: { text: string; placeId: string }) => void;
  search: (v: string) => any;
  error?: { status?: { error: boolean; message: string } };
  placeholder: string;
  selected: string;
  Loading?: (() => JSX.Element) | undefined;
};

export const Autocomplete =
  // forwardRef((props, ref) =>
  ({
    onChange,
    search,
    error = { status: { error: false, message: "" } },
    placeholder,
    selected,
    Loading = LoadingDefault,
    ...props
  }: AutocompleteProps) => {
    const [value, setValue] = useState("");
    const [results, setResults] =
      useState<{ text: string; placeId: string }[]>();
    const [showOptions, setShowOptions] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.value;
      if (!newValue) {
        onChange({ text: "", placeId: "" });
      }
      if (newValue) {
        setShowOptions(true);
      }
      onChange({ text: "", placeId: "" });
      setValue(newValue);
    };

    useEffect(() => {
      let cancelled = false;
      if (value) {
        (async () => {
          setLoading(true);
          const results = await search(value);

          if (!cancelled) {
            setLoading(false);
            setResults(results);
          }
        })();
      } else {
        setLoading(false);
        setResults([]);
        setShowOptions(false);
      }
      return () => {
        cancelled = true;
      };
    }, [value]);

    const onClick = (result: { text: string; placeId: string }) => {
      onChange(result);
      setValue("");
      setResults([]);
      setShowOptions(false);
    };

    let optionList;
    if (showOptions) {
      if (results?.length) {
        optionList = results?.map((result) => {
          const { text } = result;
          return (
            <Item
              key={text}
              onClick={() => onClick(result)}
              tabIndex={0}
              data-javelin-name={`change-location-select`}
              data-javelin-meta={JSON.stringify(result)}
            >
              {text}
            </Item>
          );
        });
      } else {
        optionList = <Item>{NO_RESULTS_TEXT}</Item>;
      }
    }
    return (
      <div style={{ position: "relative" }}>
        <FieldWrapper>
          <Input
            onChange={handleChange}
            value={value || selected || ""}
            placeholder={placeholder}
            error={error}
            {...props}
          />
          {loading ? (
            <StyledDropdown>
              <Loading />
            </StyledDropdown>
          ) : (
            !!optionList && <StyledDropdown>{optionList}</StyledDropdown>
          )}
        </FieldWrapper>
        <FieldMessage
          active={!!value || !!error?.status}
          type={error?.status ? "error" : "success"}
        />
        {/* {error && (
          <FieldMessage
            content={error.message}
            type={error.status && "error"}
            style={{
              marginTop: "0",
              marginLeft: "8px",
            }}
          />
        )} */}
      </div>
    );
  };
//   ({ ...props, ref })
// );

const FieldWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  label > & {
    margin-top: 9px;
  }
`;

const StyledInput = styled(TextField).attrs(() => ({ as: "input" }))<{
  open?: boolean;
}>`
  position: relative;
  z-index: 1;
  height: 40px;
  ${(props) =>
    props.open &&
    `border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: #dedede;
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
    z-index: 4;`};
  &:focus {
    /* border-color: #dedede; */
    /* box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24); */
  }
`;

const StyledDropdown = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #dedede;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-height: 224px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;
`;

const Item = styled.a<{ selected?: boolean }>`
  background-color: ${(props) => (props.selected ? "#f2f2f2" : "#FFFFFF")};
  border-bottom: 1px solid #dedede;
  color: #666666;
  cursor: pointer;
  display: block;
  font-weight: ${(props) => (props.selected ? 400 : 300)};
  line-height: 20px;
  position: relative;
  padding: 17px 12px;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
  &:last-of-type {
    border-bottom: 0;
  }
`;

const LoadingDefault = () => <Item>Loading...</Item>;

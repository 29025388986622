import styled from "styled-components";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";
import { RefAttributes } from "react";
import { motion } from "framer-motion";
import { navPop } from "Assets/Animate";

interface NavLinkInterface
  extends NavLinkProps,
    RefAttributes<HTMLAnchorElement> {}

export const NavLink = ({ children, ...props }: NavLinkInterface) => {
  return (
    <Container>
      <Link {...props}>{children}</Link>
      <motion.span
        variants={navPop}
        initial="initial"
        animate="animate"
      ></motion.span>
    </Container>
  );
};

const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  color: #112b4d;
  font-size: 20px;
  font-family: "F1Bold";
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 5px solid #00000000;
  width: fit-content;

  &.active {
    color: #1700f4;
    border-radius: 0;
    font-weight: 600;
    & + span {
      background: #1700f4;
    }
  }
`;
const Container = styled.div`
  overflow: hidden;
  span {
    display: block;
    width: 100%;
    height: 3.5px;
    background: white;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: fit-content;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    width: fit-content;
  }
`;

import styled from "styled-components";
import { Select2 } from "./SelectField";
import { FieldMessage } from "../FieldMessage";
import { FieldProps } from "./Text";

export const Select = ({
  onChange,
  value,
  fieldName,
  placeholder,
  options,
  error = { status: { error: false, message: "" } },
}: FieldProps) => {
  let selected = value && options?.find((option) => option.title === value);

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <StyledSelect
        placeholder={placeholder || "Select an option..."}
        selected={selected}
        options={options || []}
        error={error}
        onChange={(val: string) => handleChange(val)}
      />
      <FieldMessage
        fieldName={fieldName}
        active={!!value || !!error?.status}
        type={!!error?.status ? "error" : "success"}
      />
    </div>
  );
};

const fontColor = "#00095b";
const primaryColor = "#6E6E6E";
const borderRadius = "6px";
const errorBackground = "#EDB8B4";
const errorBorderColor = "#E45C52";
const errorFieldColor = "#6d2621";

const StyledSelect = styled(Select2)`
  border-radius: ${borderRadius};
  width: 100%;
  height: 40px;
  background-color: white;

  span {
    font-size: 12px;
    font-family: "F1Reg";
    color: #999999;
  }

  & > svg > path {
    fill: ${primaryColor};
  }
`;

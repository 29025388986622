import { firebaseApp } from "Components/Firebase";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { ConfigDocument } from "types/types";

export function useMonthList() {
  let year = DateTime.now().year;
  // const [currentMonth, setCurrentMonth] = useState<string>("September");
  const [currentYear, setCurrentYear] = useState<number>(year);

  const db = getFirestore(firebaseApp);
  const [site] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>,
  );
  useEffect(() => {
    let date = site?.data()?.startMonth;
    if (date) {
      let currDate = site?.data()?.currentMonth;
      const endDate = DateTime.fromJSDate(date?.toDate())
        .plus({ month: 9 })
        .set({ day: 15 })
        .toMillis();
      //console.log("endD", currDate?.toMillis(), endDate);
    }

    if (date) {
      setCurrentYear(
        parseInt(DateTime.fromJSDate(date?.toDate()).toFormat("yyyy")),
      );
    }
  }, [site]);

  //const currentYear
  const startDate = DateTime.local(currentYear, 9, 1);
  const endDate = startDate.plus({ month: 9 });
  const intervals = Interval.fromDateTimes(
    startDate.startOf("day"),
    endDate.endOf("day"),
  )
    .splitBy({ month: 1 })
    .map((d) => d.start?.toFormat("LLLL yyyy"));

  return intervals.map((d, i) => ({ id: i + 1, title: d }));
}

export const currentSeasonYear = DateTime.now().year;

export const yearList = () => {
  let array = [];
  for (let year = 2023; year <= currentSeasonYear; year++) {
    array.push(year);
  }
  return array.sort((a, b) => b - a);
};

import React, { useEffect, useState } from "react";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { TextField } from "Components/RegistrationForm/Fields/Field/Text";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonPrimary } from "Components/Button";
import { Body2, H3, H4, Subtitle2 } from "Components/Text";
import { Modal } from "Components/Modal";
import { useUser } from "utils/useContext";

const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export const Login = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState({ email: "", password: "" });
  const [error, setError] = useState<boolean>(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState<boolean>(false);
  const [showSendEmail, setShowSendEmail] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const onChange = (field: string, v: string) => {
    setValue({ ...value, [field]: v });
  };
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const { user } = useUser();
  const updatePasswordUI = location?.state?.updatePassword;

  useEffect(() => {
    if (updatePasswordUI) {
      setShowUpdatePassword(true);
    }
  }, [updatePasswordUI]);

  const login = () => {
    if (!!value.email && !!value.password) {
      signInWithEmailAndPassword(auth, value.email, value.password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user) {
            navigate("/admin");
          }
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(errorMessage);
          setError(true);
        });
    }
  };

  const getNewPassword = (password: string) => {
    let valid = true;
    if (!PASSWORD_REGEX.test(password)) {
      setPasswordIsValid(false);
      valid = false;
    }
    if (valid) {
      setPasswordIsValid(true);
    }
  };

  const updateUserPassword = () => {
    if (user && value.password && passwordIsValid) {
      updatePassword(user, value.password)
        .then(() => {
          setDisableButton(true);
        })
        .then(() => {
          setTimeout(() => {
            navigate("/admin");
          }, 1500);
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(errorMessage);
        });
    }
  };

  const sendEmail = () => {
    var actionCodeSettings = {
      url: "https://fordathleteofthemonth.com/login",
    };
    if (auth && value.email) {
      sendPasswordResetEmail(auth, value.email, actionCodeSettings)
        .then(() => {
          setDisableButton(true);
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.log(errorMessage);
        });
    }
  };

  return (
    <>
      {showUpdatePassword && (
        <Link
          to="/admin"
          style={{
            padding: 20,
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          {"< Back"}
        </Link>
      )}
      <Container>
        <H3>
          {showUpdatePassword && "Enter your new password"}
          {showSendEmail && "Enter your email"}
          {!showUpdatePassword && !showSendEmail && "Login"}
          {error && (
            <span style={{ color: "red" }}>
              Incorrect email or password. Please try again
            </span>
          )}
          {disableButton && showSendEmail && (
            <span style={{ color: "#1700f4" }}>Password reset email sent!</span>
          )}
          {disableButton && showUpdatePassword && (
            <span style={{ color: "#1700f4" }}>
              Your password has been successfully reset!
            </span>
          )}
        </H3>
        {(!showUpdatePassword || showSendEmail) && (
          <TextField
            value={value.email}
            onChange={(v: string) => {
              setError(false);
              onChange("email", v);
            }}
            placeholder="Enter your email"
          />
        )}
        {!showSendEmail && (
          <TextField
            value={value.password}
            type="password"
            onChange={(v: string) => {
              if (showUpdatePassword) {
                getNewPassword(v);
                onChange("password", v);
              } else {
                setError(false);
                onChange("password", v);
              }
            }}
            placeholder="Enter your password"
          />
        )}
        {showUpdatePassword && (
          <Body2>
            Password must be at least 8 characters, contain at least one special
            character, one letter, and one number
          </Body2>
        )}

        <ButtonPrimary
          onClick={() => {
            if (showUpdatePassword) {
              updateUserPassword();
            }
            if (showSendEmail) {
              setError(false);
              sendEmail();
            } else {
              login();
            }
          }}
          disabled={disableButton}
        >
          Submit
        </ButtonPrimary>

        {!showSendEmail && !showUpdatePassword && (
          <Subtitle2
            onClick={() => {
              setError(false);
              setShowSendEmail(true);
            }}
          >
            Forgot password
          </Subtitle2>
        )}
      </Container>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          {showUpdatePassword && (
            <ModalWrapper>
              <H4>
                Your password has been
                <br /> successfully updated
              </H4>
              <ButtonPrimary
                onClick={() => {
                  navigate("/admin");
                }}
              >
                Back to Admin Page
              </ButtonPrimary>
            </ModalWrapper>
          )}
        </Modal>
      )}
    </>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 350px;
  margin: 100px auto;
  gap: 20px;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 5px 5px #f2f2f2;
  padding: 40px;
  border-radius: 6px;
  span {
    position: absolute;
    bottom: -23px;
    left: 0;
    font-size: 12px;
    font-family: F1Reg;
    font-weight: 300 !important;
  }
  ${H3} {
    position: relative;
    margin-bottom: 20px;
  }
  ${Subtitle2} {
    text-decoration: underline;
    color: #1700f4;
    cursor: pointer;
    font-weight: 200;
  }
`;
const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
`;

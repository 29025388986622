import { useState, useReducer } from "react";
import styled from "styled-components";
import { reducer } from "Modules/reducer";
import { firebaseApp } from "Components/Firebase";
import {
  Timestamp,
  addDoc,
  collection,
  getFirestore,
} from "firebase/firestore";
import { ButtonPrimary } from "Components/Button";
import { ReactComponent as CheckmarkSvg } from "Assets/Svgs/checkMark.svg";
import { handleErrors } from "./handleErrors";
import { Field } from "./Fields";
import { Body1, H2, H3, Subtitle1 } from "Components/Text";
import { Autocomplete } from "Components/Autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import {
  NomineeClassifications,
  NomineeGrades,
  NomineeSports,
  NominatorRoles,
} from "types/types";

export const googleGeocoder = async (placeId: string) => {
  const geocoder = new google.maps.Geocoder();
  const { results } = await geocoder.geocode({ placeId });
  return results;
};

export const googlePrediction = async (input: string) => {
  const autocompleteService =
    new window.google.maps.places.AutocompleteService();
  const { predictions } = await autocompleteService.getPlacePredictions(
    { input },
    (
      predictions: google.maps.places.AutocompletePrediction[] | null,
      status: string
    ) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return [];
      }
      return predictions;
    }
  );
  return predictions;
};

const docFields = [
  "nomineeName",
  "nomineeSchool",
  "nomineeGrade",
  "nomineeSport",
  "coachName",
  "nomineeClassification",
  "nomineeReason",
  "parentInfo",
  "nominatorName",
  "nominatorRole",
  "nominatorEmail",
  "nominatorPhone",
];

export const RegistrationForm = () => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const dispatch = (update: { [key: string]: string }) => {
    setRegistration(update);
  };
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState<{
    [key: string]: { error: boolean; message: string };
  } | null>(null);
  const db = getFirestore(firebaseApp);

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_APIKEY || "",
    libraries: ["places"],
  });

  const handlePlacesChange = async (value: {
    text: string;
    placeId: string;
  }) => {
    dispatch({ nomineeSchool: value.text });
  };

  const handleSearch = async (queryString: string) => {
    delete errors?.["nomineeSchool"];
    setErrors(errors);
    const results = await googlePrediction(queryString);
    return (
      results
        ?.filter(
          (r: google.maps.places.AutocompletePrediction) =>
            !!r.structured_formatting.secondary_text
        )
        ?.map((c: google.maps.places.AutocompletePrediction) => ({
          text: c.structured_formatting.main_text,
          placeId: c.place_id,
        })) || []
    );
  };

  const handleChange = (value: string, name: string) => {
    dispatch({ [name]: value });
    delete errors?.[name];
    setErrors(errors);
  };

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    let errorObject = handleErrors(docFields, registration);

    if (!errorObject) {
      let date = new Date();
      await addDoc(collection(db, "nominations"), {
        ...registration,
        status: "new",
        submittedAt: Timestamp.fromDate(date),
      });
      setFormSubmitted(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setErrors(errorObject);
    }
  };

  const renderOptions = (
    arr:
      | typeof NomineeGrades
      | typeof NomineeSports
      | typeof NomineeClassifications
      | typeof NominatorRoles
  ) =>
    arr.map((val: string, idx: number) => {
      return { id: idx + 1, title: val };
    });

  return (
    <>
      <RegForm onSubmit={handleSubmit}>
        {!formSubmitted ? (
          <Form>
            <H2>Athlete's Information</H2>
            <FieldContainer>
              <Label>Nominee Name</Label>
              <Field
                type="text"
                onChange={(value: string) => handleChange(value, "nomineeName")}
                placeholder="Please type nominee name"
                fieldName="nomineeName"
                value={registration["nomineeName"] || ""}
                error={{
                  status: errors?.["nomineeName"],
                }}
              />
              <Label>Nominee School</Label>
              <Autocomplete
                placeholder="Please type nominee school"
                selected={registration["nomineeSchool"] || ""}
                onChange={(val: { text: string; placeId: string }) => {
                  if (!val) {
                    dispatch({ nomineeSchool: "" });
                  }
                  handlePlacesChange(val);
                }}
                search={handleSearch}
                error={{
                  status: errors?.["nomineeSchool"],
                }}
              />
              <Label>Nominee Grade</Label>
              <Field
                type="radio"
                onChange={(value: string) => {
                  console.log("val", value);

                  handleChange(value, "nomineeGrade");
                }}
                fieldName="nomineeGrade"
                options={renderOptions(NomineeGrades)}
                value={registration["nomineeGrade"]}
                error={{
                  status: errors?.["nomineeGrade"],
                }}
              />
              <Label>Nominee Classification</Label>
              <Field
                type="select"
                onChange={(value: string) =>
                  handleChange(value, "nomineeClassification")
                }
                placeholder="Select nominee classification"
                fieldName="nomineeClassification"
                options={renderOptions(NomineeClassifications)}
                value={registration["nomineeClassification"]}
                error={{
                  status: errors?.["nomineeClassification"],
                }}
              />
              <Label>Nominee Sport</Label>
              <Field
                type="select"
                onChange={(value: string) =>
                  handleChange(value, "nomineeSport")
                }
                options={renderOptions(NomineeSports)}
                placeholder="Select a sport"
                fieldName="nomineeSport"
                value={registration["nomineeSport"]}
                error={{
                  status: errors?.["nomineeSport"],
                }}
              />
              <Label>Nominee Coach</Label>
              <Field
                type="text"
                onChange={(value: string) => handleChange(value, "coachName")}
                placeholder="Please type nominee coach"
                fieldName="coachName"
                value={registration["coachName"]}
                error={{
                  status: errors?.["coachName"],
                }}
              />
              <Label>Stats of Nominee (optional)</Label>
              <Field
                type="text"
                onChange={(value: string) =>
                  handleChange(value, "nomineeStats")
                }
                placeholder="Please type nominee stats"
                fieldName="nomineeStats"
                value={registration["nomineeStats"]}
              />
              <Label>Reason for Nomination</Label>
              <Field
                type="text"
                onChange={(value: string) =>
                  handleChange(value, "nomineeReason")
                }
                placeholder="Please type your answer"
                fieldName="nomineeReason"
                value={registration["nomineeReason"]}
                error={{
                  status: errors?.["nomineeReason"],
                }}
              />
              <Label>Parent Contact Info of Nominee (Name, Phone, Email)</Label>
              <Field
                type="text"
                onChange={(value: string) => handleChange(value, "parentInfo")}
                placeholder="Please type your answer"
                fieldName="parentInfo"
                value={registration["parentInfo"]}
                error={{
                  status: errors?.["parentInfo"],
                }}
              />
              <H2>Nominator Information</H2>
              <Label>Nominator Name</Label>
              <Field
                type="text"
                onChange={(value: string) =>
                  handleChange(value, "nominatorName")
                }
                placeholder="Please type nominator name"
                fieldName="nominatorName"
                value={registration["nominatorName"]}
                error={{
                  status: errors?.["nominatorName"],
                }}
              />
              <Label>Nominator Role</Label>
              <Field
                type="select"
                options={renderOptions(NominatorRoles)}
                onChange={(value: string) =>
                  handleChange(value, "nominatorRole")
                }
                placeholder="Select your role"
                fieldName="nominatorRole"
                value={registration["nominatorRole"]}
                error={{
                  status: errors?.["nominatorRole"],
                }}
              />
              <Label>Nominator Email</Label>
              <Field
                type="text"
                onChange={(value: string) =>
                  handleChange(value, "nominatorEmail")
                }
                placeholder="Please type nominator Email"
                fieldName="nominatorEmail"
                value={registration["nominatorEmail"]}
                error={{
                  status: errors?.["nominatorEmail"],
                }}
              />
              <Label>Nominator Phone</Label>
              <Field
                type="phone"
                onChange={(value: string) =>
                  handleChange(value, "nominatorPhone")
                }
                placeholder="Please type nominator Phone"
                fieldName="nominatorPhone"
                value={registration["nominatorPhone"]}
                error={{
                  status: errors?.["nominatorPhone"],
                }}
              />
            </FieldContainer>
          </Form>
        ) : (
          <ConfirmContainer>
            <CheckmarkSvg />
            <H2>Nomination Submitted</H2>
            <Body1>
              Your nomination was successfully submitted. We will be reviewing
              your athlete and inform you if they are chosen as a winner.
            </Body1>
            <Subtitle1
              onClick={() => {
                setFormSubmitted(false);
                dispatch({});
              }}
            >
              Nominate Another Athlete
            </Subtitle1>
          </ConfirmContainer>
        )}
        {!formSubmitted && <RegisterButton>Submit Nomination</RegisterButton>}
      </RegForm>
    </>
  );
};

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
  margin: 0 0 20px;
  width: 100%;
  & > ${H2} {
    margin: 0px 0 -10px;
    color: #112b4d;
  }
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Label = styled.span`
  color: #112b4d;
  font-size: 13px;
  margin: 22px 0 13px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > ${H2} {
    margin: 20px 0 10px;
    color: #112b4d;
  }
`;
const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 25px;
  padding: 35px 25px;
  text-align: center;
  border-radius: 12px;
  background-color: #f2f5f7;
  & > ${H3} {
    color: #112b4d;
  }
  & > ${Subtitle1} {
    font-size: 16px;
    color: #1700f4;
    cursor: pointer;
  }
`;

const RegisterButton = styled(ButtonPrimary)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 10px !important;
  }
`;

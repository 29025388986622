import styled, { css } from "styled-components";
import { H4, Body2 } from "Components/Text";
import LogoAthlete from "Assets/Images/LogoAthlete.png";
import FordLogo from "Assets/Images/FordLogo.png";
import { Menu } from "Components/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useClickOutside } from "utils/useClickOutside";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

export const Header = () => {
  const { pathname } = useLocation();
  const isAdminView = pathname?.includes("admin");
  const navigate = useNavigate();
  const auth = getAuth();
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  useClickOutside(referenceElement, () => setOpen(false));

  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container>
      <LogoWrapper>
        <Logo
          src={LogoAthlete}
          alt="Female Athlete of the Month all sports logo"
        />
        <Title>
          <Body2>GREATER TEXAS FORD DEALERS</Body2>
          <H4>Female Athlete of the Month</H4>
        </Title>
        {!isAdminView && (
          <Logo
            src={FordLogo}
            alt="Texas Ford logo"
            style={{ marginLeft: 20 }}
          />
        )}
      </LogoWrapper>
      {isAdminView ? (
        <Right ref={setReferenceElement}>
          <UserAvatar onClick={() => setOpen(!open)}>
            {user?.email?.slice(0, 1).toUpperCase() || "F"}
          </UserAvatar>
          {open && (
            <DropdownMenu>
              <Option
                onClick={() => {
                  navigate("/login", { state: { updatePassword: true } });
                }}
              >
                Update password
              </Option>
              <Option
                onClick={() => {
                  signOutUser();
                }}
              >
                Sign Out
              </Option>
            </DropdownMenu>
          )}
        </Right>
      ) : (
        <Menu />
      )}
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Logo = styled.img<{ isAdmin?: boolean }>`
  width: 100%;
  max-width: 75px;
  object-fit: cover;
`;
export const UserAvatar = styled.div`
  height: 47px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  background: #1700f4;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;
const DropdownMenu = styled.div`
  width: 300px;
  position: absolute;
  top: 105%;
  right: 0;
  z-index: 100;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
`;

const Option = styled.div`
  display: block;
  text-decoration: none;
  padding: 8px;
  cursor: pointer;
  user-select: none;
  &:first-child {
    border-bottom: 1px solid #cccccc82;
  }
  &:hover {
    background: #e6e9eb;
  }
`;

const Info = styled.div`
  padding: 8px;
  font-size: 14px;
`;

import { Facebook, Instagram, TikTok, Twitter, Youtube } from "./icons";

export const socials = [
  {
    key: "facebook",
    Image: Facebook,
  },
  {
    key: "instagram",
    Image: Instagram,
  },
  { key: "twitter", Image: Twitter },
  {
    key: "tiktok",
    Image: Youtube,
  },
];

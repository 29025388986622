import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  collection,
  getFirestore,
  query,
  doc,
  setDoc,
  QuerySnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Header, UserAvatar as AddButton } from "Components/Header";
import styled, { css } from "styled-components";
import { H1, Body1, H2, H3, Subtitle1 } from "Components/Text";
import { Main } from "../Home";
import { Footer } from "Components/Footer";
import { Fragment, useEffect, useRef, useState } from "react";
import { AthleteData } from "Routes/Alumni";
import { DateTime } from "luxon";
import {
  NominationDocument,
  NominationStatus,
  ConfigDocument,
  WinnerDocument,
  YearWinnersDocument,
} from "types/types";
import { AthleteChip } from "Components/AthleteChip";
import incompleteImg from "Assets/Images/incompleteImg.png";
import { ListItem } from "Components/ListItem";
import { NomineeClassifications } from "types/types";
import { ReactComponent as AddSvg } from "Assets/Svgs/AddAlt.svg";
import { ReactComponent as ChevronSvg } from "Assets/Svgs/ChevronDown.svg";
import { currentSeasonYear, useMonthList } from "utils/useTimelineList";
import { Dropdown } from "Components/RegistrationForm/Fields/Field/SelectField";
import { WinnerModal } from "Components/WinnerModal";
import { ListSection } from "Components/ListSection";
import { getAuth } from "firebase/auth";
import { Modal } from "Components/Modal";
import { YearWinnerModal } from "Components/YearWinnerModal";
import { ButtonPrimary } from "Components/Button";

let update = {
  toFirestore: (data: WithFieldValue<AthleteData>) => ({ ...data }),
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<AthleteData>,
    options: SnapshotOptions,
  ) => {
    let data = snapshot.data(options);
    let date = data.featuredDate;
    return {
      ...data,
      featuredMonth: DateTime.fromJSDate(date.toDate()).toFormat("LLLL"),
      featuredYear: DateTime.fromJSDate(date.toDate()).toFormat("yyyy"),
    };
  },
};

let requiredFields = [
  "athleteName",
  "athleteSchool",
  "athleteGrade",
  "athleteClassification",
  "athleteSport",
  "athletePhoto",
  "athleteProfile",
  "featuredDate",
] as const;
type Fields = (typeof requiredFields)[number];

const requiredYearFields = [
  "athleteName",
  "athleteSchool",
  "athleteGrade",
  "athleteClassification",
  "athleteSport",
  "athletePhoto",
] as const;
type YearFields = (typeof requiredYearFields)[number];

export const Admin = () => {
  const [currentMonth, setCurrentMonth] = useState<string>("");
  const [upcomingMonth, setUpcomingMonth] = useState<string>("");
  const [incompleteMonth, setIncompleteMonth] = useState<boolean>(false);
  const [incompleteYear, setIncompleteYear] = useState<boolean>(false);
  const [currentYear, setCurrentYear] = useState<number | null>();
  const [openYearModal, setOpenYearModal] = useState(false);
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("win");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [openList, setOpenList] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showMonthList, setShowMonthList] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [activeSeasons, setActiveSeasons] = useState<number[]>();
  const [completeSeason, setCompleteSeason] = useState<number>();

  const classRef = useRef<string | null>(null);
  const classArrayRef = useRef<string[]>([]);
  const dateRef = useRef<string | null>(null);

  const dateOptions = useMonthList();

  const db = getFirestore(firebaseApp);
  const ref = query(collection(db, "winners")).withConverter(
    update,
  ) as CollectionReference<AthleteData>;

  const [dataSnapshot, loading, error] = useCollection(ref);
  let winnerDocs = dataSnapshot?.docs;

  const [nominationDocs] = useCollection(
    query(
      collection(db, "nominations") as CollectionReference<NominationDocument>,
    ),
  );

  const [winnerByYearDocs] = useCollection(
    query(
      collection(db, "yearWinners") as CollectionReference<YearWinnersDocument>,
    ),
  );
  const winnerYearDocs = winnerByYearDocs?.docs;

  const [siteData] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>,
  );

  useEffect(() => {
    const month = siteData?.data()?.currentMonth;
    const startMonth = siteData?.data()?.startMonth;
    const season = siteData?.data()?.completeSeason;
    const activeSeasonList = siteData?.data()?.activeSeasons;
    if (activeSeasonList) {
      setActiveSeasons(activeSeasonList);
    }
    if (season) {
      setCompleteSeason(DateTime.fromJSDate(season.toDate()).year);
    }
    if (month && startMonth) {
      setCurrentYear(DateTime.fromJSDate(startMonth.toDate()).year);
      setCurrentMonth(
        DateTime.fromJSDate(month?.toDate()).toFormat("LLLL yyyy"),
      );
      if (DateTime.fromJSDate(month?.toDate()).toFormat("LLLL") === "June") {
        setUpcomingMonth(
          DateTime.fromJSDate(startMonth?.toDate()).toFormat("LLLL yyyy"),
        );
      } else {
        let upcoming = DateTime.fromJSDate(month?.toDate())
          .plus({ months: 1 })
          .toFormat("LLLL yyyy");
        setUpcomingMonth(upcoming);
      }
    }
  }, [siteData]);

  useEffect(() => {
    let complete = true;
    const filteredWinners =
      winnerDocs?.filter(
        (doc) =>
          DateTime.fromJSDate(doc.data().featuredDate.toDate()).toFormat(
            "LLLL yyyy",
          ) === upcomingMonth,
      ) ?? [];
    if (filteredWinners.length) {
      filteredWinners.forEach((doc: QueryDocumentSnapshot<AthleteData>) => {
        if (!completeCheck(doc.data())) return (complete = false);
      });
    }
    if (!complete || filteredWinners.length < 7) {
      setIncompleteMonth(true);
    } else if (complete && filteredWinners.length === 7) {
      setIncompleteMonth(false);
    }
  }, [upcomingMonth, winnerDocs]);

  useEffect(() => {
    // year
    let complete = true;
    const filteredWinners =
      winnerYearDocs?.filter((doc) => {
        const [featuredYear] = doc?.id.split("-");
        return parseInt(featuredYear) + 2000 === currentYear;
      }) ?? [];
    if (filteredWinners.length) {
      filteredWinners.forEach(
        (doc: QueryDocumentSnapshot<YearWinnersDocument>) => {
          if (!completeYearCheck(doc.data())) return (complete = false);
        },
      );
    }
    if (!complete || filteredWinners.length < 7) {
      setIncompleteYear(true);
    } else if (complete && filteredWinners.length === 7) {
      setIncompleteYear(false);
    }
  }, [currentYear, winnerYearDocs]);

  const completeCheck = (athleteData: WinnerDocument) => {
    return requiredFields.reduce((acc: boolean, field: Fields) => {
      if (
        !Object.keys(athleteData).includes(field) ||
        athleteData[field as keyof WinnerDocument] === ""
      ) {
        return (acc = false);
      }
      return acc;
    }, true);
  };

  const filterWinners = (classification: string) => {
    let complete = true;
    const [filteredWinner] =
      winnerDocs?.filter((doc) => {
        return (
          DateTime.fromJSDate(doc.data().featuredDate.toDate()).toFormat(
            "LLLL yyyy",
          ) === upcomingMonth &&
          classification === doc.data().athleteClassification
        );
      }) ?? [];
    let winner = filteredWinner?.data();
    if (winner) {
      complete = completeCheck(winner);
    }
    if (!winner) {
      return (
        <AddButtonWrapper
          onClick={() => openWinnerModal(classification, upcomingMonth)}
        >
          <Subtitle1>{classification}</Subtitle1>
          <AddButton>
            <AddSvg />
          </AddButton>
        </AddButtonWrapper>
      );
    }
    return (
      <AthleteChip
        winnerId={filteredWinner?.id}
        winnerDoc={winner}
        key={winner.athletePhoto}
        src={winner.athletePhoto || incompleteImg}
        name={winner.athleteName || ""}
        grade={winner.athleteGrade || ""}
        category={winner.athleteClassification || ""}
        details={winner.athleteProfile || ""}
        upcomingStyle={true}
        completeProfile={complete}
        classificationList={classArrayRef.current}
      />
    );
  };

  const updateCurrentMonth = async () => {
    if (upcomingMonth && !incompleteMonth) {
      const docRef = doc(db, "config", "site");
      const updatedDate = DateTime.fromFormat(upcomingMonth, "LLLL yyyy");
      if (upcomingMonth.split(" ")[0] === "September" && currentYear) {
        const completeYearList = [...(activeSeasons || [])];
        if (!completeYearList.includes(currentYear)) {
          completeYearList.push(currentYear);
        }
        await setDoc(
          docRef,
          {
            activeSeasons: completeYearList,
          },
          { merge: true },
        );
      }
      await setDoc(
        docRef,
        {
          currentMonth: Timestamp.fromDate(
            updatedDate.set({ day: 15 }).toJSDate(),
          ),
        },
        { merge: true },
      );
    }
  };

  const publishCurrentYear = async () => {
    if (currentYear && !incompleteYear) {
      const completeYear = DateTime.fromFormat(currentYear.toString(), "yyyy");
      const updatedStartMonth = DateTime.fromFormat(
        (currentYear + 1).toString(),
        "yyyy",
      );
      await setDoc(
        doc(db, "config", "site"),
        {
          completeSeason: Timestamp.fromDate(
            completeYear.set({ day: 15, month: 9 }).toJSDate(),
          ),
          startMonth: Timestamp.fromDate(
            updatedStartMonth.set({ day: 15, month: 9 }).toJSDate(),
          ),
          currentMonth: Timestamp.fromDate(
            updatedStartMonth.set({ day: 15, month: 6 }).toJSDate(),
          ),
        },
        { merge: true },
      );
    }
  };

  const updateStatus = async (
    snap: QuerySnapshot<NominationDocument> | undefined,
    status: NominationStatus,
  ) => {
    snap?.docs.map((nominationDoc) => {
      if (selectIds?.includes(nominationDoc?.id)) {
        setDoc(
          nominationDoc.ref,
          {
            status: status,
          },
          { merge: true },
        );
      }
    });
    setSelectIds([]);
  };

  const openWinnerModal = (classification: string, featuredMonth: string) => {
    setOpenModal(true);
    classRef.current = classification;
    dateRef.current = featuredMonth;
  };

  const openYearWinnerModal = (
    classification: string,
    featuredYear: string,
  ) => {
    setOpenYearModal(true);
    classRef.current = classification;
    dateRef.current = featuredYear;
  };

  const renderMonthWinnerList = (
    winnerDocs?: QueryDocumentSnapshot<AthleteData>[],
  ) => {
    let monthList = winnerDocs
      ?.reduce((arr: string[], doc: QueryDocumentSnapshot<AthleteData>) => {
        let docMonth = DateTime.fromJSDate(
          doc?.data()?.featuredDate.toDate(),
        ).toFormat("LLLL yyyy");
        if (arr.indexOf(docMonth) === -1) arr.push(docMonth);
        return arr;
      }, [])
      .sort((a: string, b: string) => {
        return (
          DateTime.fromFormat(b, "LLLL yyyy").toMillis() -
          DateTime.fromFormat(a, "LLLL yyyy").toMillis()
        );
      });
    return monthList?.map((date: string) => {
      const winnersPerMonth = winnerDocs?.reduce(
        (
          arr: QueryDocumentSnapshot<AthleteData>[],
          winner: QueryDocumentSnapshot<AthleteData>,
        ) => {
          date ===
            DateTime.fromJSDate(winner.data().featuredDate.toDate()).toFormat(
              "LLLL yyyy",
            ) && arr.push(winner);

          return arr;
        },
        [],
      );
      return {
        date,
        winnerDocs: winnersPerMonth,
        last: monthList?.[0] || "",
      };
    });
  };
  const renderYearWinnerList = (
    winnerDocs?: QueryDocumentSnapshot<YearWinnersDocument>[],
  ) => {
    let yearList = winnerDocs
      ?.reduce(
        (arr: string[], doc: QueryDocumentSnapshot<YearWinnersDocument>) => {
          const [docYear] = doc.id.split("-");
          if (arr.indexOf(docYear) === -1) arr.push(docYear);
          return arr;
        },
        [],
      )
      .sort((a: string, b: string) => {
        return parseInt(b) - parseInt(a);
      });
    return yearList?.map((date: string) => {
      const winnersPerYear = winnerDocs?.reduce(
        (
          arr: QueryDocumentSnapshot<YearWinnersDocument>[],
          winner: QueryDocumentSnapshot<YearWinnersDocument>,
        ) => {
          const [docYear] = winner.id.split("-");
          date === docYear && arr.push(winner);

          return arr;
        },
        [],
      );
      return {
        date,
        winnerDocs: winnersPerYear,
        yearly: true,
      };
    });
  };

  const completeYearCheck = (athleteData: YearWinnersDocument) => {
    return requiredYearFields.reduce((acc: boolean, field: YearFields) => {
      if (
        !Object.keys(athleteData).includes(field) ||
        athleteData[field as keyof YearWinnersDocument] === ""
      ) {
        return (acc = false);
      }
      return acc;
    }, true);
  };

  const filterYearWinners = (
    classification: string,
    upcomingCheck?: boolean,
  ) => {
    let complete = true;
    const [filteredWinner] =
      winnerYearDocs?.filter((doc) => {
        const [featuredYear] = doc?.id.split("-");
        return (
          parseInt(featuredYear) + 2000 ===
            (upcomingCheck
              ? parseInt(
                  DateTime.fromJSDate(
                    siteData?.get("startMonth").toDate(),
                  ).toFormat("yyyy"),
                )
              : completeSeason) &&
          classification === doc.data().athleteClassification
        );
      }) ?? [];
    let winner = filteredWinner?.data();
    if (winner) {
      complete = completeYearCheck(winner);
    }
    if (!winner) {
      return (
        <AddButtonWrapper
          key={classification}
          onClick={() =>
            openYearWinnerModal(
              classification,
              currentYear?.toString()?.slice(2, 4) || "",
            )
          }
        >
          <Subtitle1>{classification}</Subtitle1>
          <AddButton>
            <AddSvg />
          </AddButton>
        </AddButtonWrapper>
      );
    }
    return (
      <AthleteChip
        winnerId={filteredWinner?.id}
        yearWinnerDoc={winner}
        key={filteredWinner?.id}
        src={winner.athletePhoto || incompleteImg}
        name={winner.athleteName || ""}
        grade={winner.athleteGrade || ""}
        category={winner.athleteClassification || ""}
        details={""}
        upcomingStyle={upcomingCheck}
        completeProfile={complete}
        classificationList={classArrayRef.current}
      />
    );
  };

  return (
    <Main>
      <Header />
      <Container>
        <H1>Nominations</H1>
        <Content>
          {currentMonth.split(" ")[0] === "June" ? (
            <WinnerContainer>
              <HeaderContainer>
                <H2>
                  {completeSeason}-{(completeSeason || currentSeasonYear) + 1}{" "}
                  Athletes of the Year
                </H2>
              </HeaderContainer>
              <Winner>
                {NomineeClassifications.map((classification: string) =>
                  filterYearWinners(classification),
                )}
              </Winner>
            </WinnerContainer>
          ) : (
            <WinnerContainer>
              <HeaderContainer>
                <H2>{currentMonth} Winners</H2>
                <Subtitle1>Current Month</Subtitle1>
              </HeaderContainer>
              <Winner>
                {winnerDocs
                  ?.sort(
                    (a, b) =>
                      parseInt(b.data().athleteClassification || "0") -
                      parseInt(a.data().athleteClassification || "0"),
                  )
                  ?.filter(
                    (doc) =>
                      DateTime.fromJSDate(
                        doc.data().featuredDate.toDate(),
                      ).toFormat("LLLL yyyy") === currentMonth,
                  )
                  ?.map((doc) => {
                    let docData = doc.data();
                    return (
                      <AthleteChip
                        winnerId={doc.id}
                        winnerDoc={docData}
                        key={docData.athletePhoto}
                        src={docData.athletePhoto || incompleteImg}
                        name={docData?.athleteName || ""}
                        grade={docData?.athleteGrade || ""}
                        sport={docData?.athleteSport || ""}
                        school={docData?.athleteSchool || ""}
                        category={docData?.athleteClassification || ""}
                        details={docData?.athleteProfile || ""}
                        classificationList={classArrayRef.current}
                      />
                    );
                  })}
              </Winner>
            </WinnerContainer>
          )}

          {upcomingMonth.split(" ")[0] === "June" ? (
            <WinnerContainer $lightMode>
              <HeaderContainer>
                <H2>
                  {currentYear}-{(currentYear || currentSeasonYear) + 1}{" "}
                  Athletes of the Year
                </H2>
                <ActionContainer>
                  <Subtitle1
                    style={{
                      color: incompleteYear ? "#999" : "#112B4D",
                      textDecoration: "underline",
                      cursor: incompleteYear ? "auto" : "pointer",
                      zIndex: 20,
                    }}
                    onClick={() => publishCurrentYear()}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                  >
                    Publish
                    {showTooltip && incompleteYear && (
                      <span>
                        Complete athletes profiles to publish this year{" "}
                      </span>
                    )}
                  </Subtitle1>
                  <StyledSubtitle1 onClick={() => setOpenList(!openList)}>
                    Select Time Period <ChevronSvg />
                    <Dropdown open={openList}>
                      {dateOptions
                        .filter((m) => m.title !== currentMonth)
                        .splice(0, dateOptions.length - 2)
                        .map((option) => {
                          const { title, id } = option;
                          return (
                            <DropdownItem
                              key={id}
                              selected={title === upcomingMonth}
                              onClick={() => {
                                setUpcomingMonth(option?.title || "");
                                setOpenList(false);
                              }}
                            >
                              <Fragment>{title}</Fragment>
                            </DropdownItem>
                          );
                        })}
                      <DropdownItem
                        key={11}
                        selected={true}
                        onClick={() => {
                          setOpenList(false);
                        }}
                      >
                        <Fragment>
                          Athlete of the Year '
                          {DateTime.fromJSDate(
                            siteData?.get("startMonth").toDate(),
                          ).toFormat("yy")}
                          -'
                          {DateTime.fromJSDate(
                            siteData?.get("startMonth").toDate(),
                          )
                            .plus({ year: 1 })
                            .toFormat("yy")}
                        </Fragment>
                      </DropdownItem>
                    </Dropdown>
                  </StyledSubtitle1>
                </ActionContainer>
              </HeaderContainer>
              <Winner>
                {NomineeClassifications.map((classification: string) =>
                  filterYearWinners(classification, true),
                )}
              </Winner>
            </WinnerContainer>
          ) : (
            <WinnerContainer $upcoming>
              <HeaderContainer>
                <H2 style={{ color: "#112B4D" }}>{upcomingMonth} Winners</H2>
                <ActionContainer>
                  <Subtitle1
                    style={{
                      color: incompleteMonth ? "#999" : "#112B4D",
                      textDecoration: "underline",
                      cursor: incompleteMonth ? "auto" : "pointer",
                      zIndex: 20,
                    }}
                    onClick={() => {
                      updateCurrentMonth();
                    }}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseOut={() => setShowTooltip(false)}
                  >
                    Set as Current Month
                    {showTooltip && incompleteMonth && (
                      <span>
                        Complete athletes profiles to set this month as current{" "}
                      </span>
                    )}
                  </Subtitle1>
                  <StyledSubtitle1 onClick={() => setOpenList(!openList)}>
                    Select Time Period <ChevronSvg />
                    <Dropdown open={openList}>
                      {dateOptions
                        .filter((m) => m.title !== currentMonth)
                        .map((option) => {
                          const { title, id } = option;
                          return (
                            <DropdownItem
                              key={id}
                              selected={title === upcomingMonth}
                              onClick={() => {
                                setUpcomingMonth(option?.title || "");
                                setOpenList(false);
                              }}
                            >
                              <Fragment>
                                {title?.split(" ")[0] === "June"
                                  ? `Athlete of the Year 
                          '${DateTime.fromJSDate(
                            siteData?.get("startMonth").toDate(),
                          ).toFormat("yy")}-'${DateTime.fromJSDate(
                                      siteData?.get("startMonth").toDate(),
                                    )
                                      .plus({ year: 1 })
                                      .toFormat("yy")}`
                                  : title}
                              </Fragment>
                            </DropdownItem>
                          );
                        })}
                    </Dropdown>
                  </StyledSubtitle1>
                </ActionContainer>
              </HeaderContainer>
              <Winner>
                {NomineeClassifications.map((classification: string) =>
                  filterWinners(classification),
                )}
              </Winner>
            </WinnerContainer>
          )}

          <StatusContainer>
            <TabsContainer>
              {/* <Tab
                active={activeTab === "new"}
                onClick={() => {
                  setActiveTab("new");
                  setSelectIds([]);
                }}
              >
                New
              </Tab>
              <Tab
                active={activeTab === "consideration"}
                onClick={() => {
                  setActiveTab("consideration");
                  setSelectIds([]);
                }}
              >
                In Consideration
              </Tab> */}
              <Tab
                active={activeTab === "win"}
                onClick={() => {
                  setActiveTab("win");
                  setSelectIds([]);
                }}
              >
                Past Winners
              </Tab>
            </TabsContainer>
            <ListContainer>
              {/* <ListHeader>
                <div></div>
                <div>Athlete Name</div>
                <div>School</div>
                <div>Class.</div>
                <div>Grade</div>
                <div>Sport</div>
               <div>Nominator</div>
                <div>Nomination</div>
                <div>Received</div> 
              </ListHeader> */}
              <List>
                {(activeTab === "new" || activeTab === "consideration") &&
                  nominationDocs?.docs.map((doc) => {
                    const data = doc.data();
                    return (
                      data.status === activeTab && (
                        <ListItem
                          docId={doc.id}
                          athleteName={data.nomineeName}
                          athleteSchool={data.nomineeSchool}
                          athleteClassification={data.nomineeClassification}
                          athleteSport={data.nomineeSport}
                          nominatorRole={data.nominatorRole}
                          setSelectIds={setSelectIds}
                          selectIds={selectIds}
                          // modalData={data}
                          docData={nominationDocs}
                          // submittedAt={data}
                        />
                      )
                    );
                  })}

                {activeTab === "win" && (
                  <>
                    <ButtonContainer>
                      <ButtonPrimary
                        inactive={!showMonthList}
                        onClick={() => setShowMonthList(true)}
                      >
                        Past Monthly Winners
                      </ButtonPrimary>
                      <ButtonPrimary
                        disabled={!winnerYearDocs?.length}
                        inactive={showMonthList}
                        onClick={() => setShowMonthList(false)}
                      >
                        Past Yearly Winners
                      </ButtonPrimary>
                    </ButtonContainer>
                    {showMonthList
                      ? renderMonthWinnerList(winnerDocs)?.map((w) => (
                          <ListSection {...w} />
                        ))
                      : renderYearWinnerList(winnerYearDocs)?.map((w) => (
                          <ListSection {...w} />
                        ))}
                  </>
                )}
              </List>

              {/* {(activeTab === "new" || activeTab === "consideration") && (
                <ButtonWrapper>
                  {activeTab === "new" ? (
                    <>
                      <SelectButton
                        onClick={() =>
                          updateStatus(nominationDocs, "consideration")
                        }
                      >
                        In Consideration
                      </SelectButton>
                      <SelectButton
                        onClick={() => updateStatus(nominationDocs, "archived")}
                      >
                        Archive
                      </SelectButton>
                    </>
                  ) : (
                    <SelectButton
                      onClick={() => updateStatus(nominationDocs, "archived")}
                    >
                      Archive
                    </SelectButton>
                  )}
                </ButtonWrapper>
              )} */}
            </ListContainer>
          </StatusContainer>
          {/* <ArchiveContainer>
            <H2
              style={{ color: "#112B4D" }}
              onClick={() => setShowArchived(!showArchived)}
            >
              Archived <StyledChevronSvg flipUp={showArchived} />
            </H2>
            {showArchived && (
              <ListContainer archived>
                <ListHeader>
                  <div></div>
                  <div>Athlete Name</div>
                  <div>School</div>
                  <div>Class.</div>
                  <div>Sport</div>
                  <div>Nominator</div>
                  <div>Nomination</div>
                  <div>Received</div>
                </ListHeader>
                <List>
                  {nominationDocs?.docs.map((doc) => {
                    const data = doc.data();
                    return (
                      doc.data().status === "archived" && (
                        <ListItem
                          docId={doc.id}
                          athleteName={data.nomineeName}
                          athleteSchool={data.nomineeSchool}
                          athleteClassification={data.nomineeClassification}
                          athleteSport={data.nomineeSport}
                          nominatorRole={data.nominatorRole}
                          setSelectIds={setSelectIds}
                          selectIds={selectIds}
                          modalData={data}
                          // submittedAt={data}
                        />
                      )
                    );
                  })}
                </List>
                <ButtonWrapper>
                  <SelectButton
                    onClick={() => updateStatus(nominationDocs, "new")}
                  >
                    Move to New
                  </SelectButton>
                  <SelectButton
                    onClick={() =>
                      updateStatus(nominationDocs, "consideration")
                    }
                  >
                    In Consideration
                  </SelectButton>
                </ButtonWrapper>
              </ListContainer>
            )}
          </ArchiveContainer> */}
        </Content>
      </Container>
      {openModal && (
        <WinnerModal
          setIsOpen={setOpenModal}
          isOpen={openModal}
          classification={classRef.current}
          classificationList={classArrayRef.current}
          date={dateRef.current}
        />
      )}
      {openYearModal && (
        <YearWinnerModal
          setIsOpen={setOpenYearModal}
          isOpen={openYearModal}
          classification={classRef.current}
          classificationList={classArrayRef.current}
          date={dateRef.current}
        />
      )}
      <Footer />
    </Main>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 50px;
  color: #112b4d;
  ${H1} {
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 20px;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px 0;
`;
const WinnerContainer = styled.div<{
  $upcoming?: boolean;
  $lightMode?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  background-color: #00095b;
  border-radius: 10px;
  ${({ $upcoming }) =>
    $upcoming &&
    css`
      background-color: #f2f5f7;
      color: #112b4d;
    `}
  ${({ $lightMode }) =>
    $lightMode &&
    css`
      background-color: #f2f5f7;
      ${H2} {
        color: #112b4d;
      }
    `}
`;
const Winner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  border-radius: 10px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 30px;
  }
`;
const HeaderContainer = styled.div`
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 30px 30px;
`;
const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: flex-start;
  justify-content: flex-end;
  // padding-right: 20px;
  cursor: pointer;
  ${Subtitle1} {
    position: relative;
    & > span {
      position: absolute;
      top: 108%;
      right: 0;
      font-size: 12px;
      background-color: #ffffff;
      box-shadow: 2px 2px 20px 8px #a6a6a66c;
      padding: 7px 20px 7px 10px;
      color: #333;
      width: 210px;
      border-radius: 6px;
      font-weight: 100;
    }
  }
  svg {
    width: 15px;
    // height: 2px;
    margin: 7px 0 -10px 3px;
    position: absolute;
    bottom: 40%;
  }
`;
const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const ListContainer = styled.div<{ archived?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 10px 10px 25px;
  border-radius: 0 0 10px 10px;
  background-color: #f2f5f7;
  ${({ archived }) =>
    archived &&
    css`
      background-color: #fff;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 5px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 5px;
  }
`;
const ListHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1.1fr 1.3fr 0.8fr 1fr 1fr;
  padding: 10px;
  & > div {
    font-size: 17px;
    font-weight: 600;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    & > div {
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const TabsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
`;
const Tab = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  font-weight: 700;
  font-size: 30px;
  color: #999999;
  ${({ active }) =>
    active &&
    css`
      background-color: #f2f5f7;
      color: #112b4d;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 18px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 18px;
  }
`;
const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  margin: 15px 0 45px;
  cursor: pointer;
`;
const SelectButton = styled.div`
  font-size: 18px;
  text-decoration: underline;
  padding: 10px;
  margin-top: 15px;
  color: #999999;
  font-weight: 700;
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 12px;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 14px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 20px;
`;
const ArchiveContainer = styled.div<{ upcoming?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  color: #112b4d;
  ${H2} {
    width: 100%;
    padding: 0 0 20px 10px;
    border-bottom: 1px solid #000;
    svg {
      margin: 3px 0 -3px 0;
    }
  }
  ${({ upcoming }) => upcoming && css``}
`;
const StyledChevronSvg = styled(ChevronSvg)<{ $rotate: boolean }>`
  cursor: pointer;
  transition: transform 0.4s ease;
  ${({ $rotate }) =>
    $rotate &&
    css`
      transform: rotate(180deg);
    `}
`;
const StyledSubtitle1 = styled(Subtitle1)<{ $header?: boolean }>`
  color: #112b4d;
  width: 190px;
  text-align: right;
  padding-right: 20px;
`;
export const DropdownItem = styled.div<{ selected?: boolean }>`
  align-items: flex-end;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-color: #dedede;
  border-bottom-width: 1px;
  box-sizing: border-box;
  padding: 12px 10px;
  color: black;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  line-height: 18px;
  position: relative;
  font-size: 13px;
  font-weight: 300;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  ${({ selected }) =>
    selected &&
    css`
      color: #999;
      pointer-events: none;
      cursor: none;
    `}
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 30px 60px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 10px;
    flex-direction: column;
    padding: 20px;
  }
`;

import { Timestamp } from "firebase/firestore"

export const NomineeClassifications = [ "6A", "5A", "4A", "3A", "2A", "1A", "Private" ] as const
type NomineeClassification = typeof NomineeClassifications[number]

export const NominationStatuses = [ "new", "archived", "consideration", "winner" ] as const
export type NominationStatus = typeof NominationStatuses[number]

export const NomineeGrades = [ "Freshman", "Sophomore", "Junior", "Senior" ] as const
type NomineeGrade = typeof NomineeGrades[number]

export const NomineeSports = [ "Tennis", "Golf", "Water Polo", "Swimming/Diving", "Volleyball", "Volleyball/Cross Country", "Soccer", "Softball", "Basketball", "Cross Country", "Track & Field", "Wrestling", "Multi-Athlete" ] as const
type NomineeSport = typeof NomineeSports[number]

export const NominatorRoles = [ "Coach", "Director", "Administrator/Teacher", "Family", "Friend/Fan" ] as const
type NominatorRole = typeof NominatorRoles[number]

export interface NominationDocument<T=Timestamp> {
    nomineeName: string;
    nomineeSchool?: string;
    nomineeGrade: NomineeGrade;
    nomineeClassification: NomineeClassification;
    nomineeSport: NomineeSport;
    coachName: string;
    nomineeStats?: string;
    nomineeReason: string;
    parentInfo: string;
    nominatorName: string;
    nominatorRole: NominatorRole;
    nominatorEmail: string;
    nominatorPhone: string;
    submittedAt?: T;
    status: NominationStatus;
    id?: string;
}   
export interface WinnerDocument<T=Timestamp> {
    athleteName: string;
    athleteSchool: string;
    athleteGrade: NomineeGrade;
    athleteClassification: NomineeClassification;
    athleteSport: string;
    athletePosition?: string;
    athletePhoto?: string;
    athleteProfile?: string;
    nominatorRole?: NominatorRole;
    featuredDate: T;
    status?: NominationStatus;
    nominationId?: string;
    winnerId?: string;
}   

export interface ConfigDocument<T=Timestamp> {
    currentMonth: T;
    startMonth: T;
    completeSeason: T;
    activeSeasons: number[];
}

export interface YearWinnersDocument<T=Timestamp> {
    athleteName: string;
    athleteClassification: NomineeClassification;
    athleteSchool: string;
    athleteGrade: NomineeGrade;
    athleteSport: string;
    athletePhoto?: string;
    athleteProfile?: string;
    featuredDate?: T;
    id?: string;
}
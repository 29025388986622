import React, { forwardRef } from "react";
import styled from "styled-components";

type AutocompleteProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  ref: React.ForwardedRef<HTMLDivElement>;
  error?: { status?: { error: boolean; message: string } };
};

export const InputLeftContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 5;
`;
export const InputRightContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 5;
`;

export const InputContainer = styled.div<{ error?: boolean }>`
  background-color: white;
  color: #333333;
  border-radius: 3px;
  font-size: 14px;
  display: block;
  position: relative;
  z-index: 0;
  flex-grow: 1;
`;

export const TextField = styled.input<{ error?: boolean }>`
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  font-family: "F1Reg";
  font-weight: 500;
  color: #333333;
  border-color: #cccccc;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  outline: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  width: 100%;
  z-index: 2;
  -webkit-appearance: none;
  ${(props) =>
    props.error &&
    `
    border-width: 1px;
    padding-top: 6px;
    padding-bottom: 6px;
    `}
  border: ${(props) =>
    props.error ? "1px solid #e45c52" : "1px solid #cccccc"};
`;

export const Input = forwardRef<HTMLDivElement, AutocompleteProps>(
  (props, ref) =>
    (({
      onChange,
      value,
      placeholder,
      error,
      // ...props
    }) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        return onChange(e);
      };
      return (
        <InputContainer error={!!error}>
          <InputPlaceholder
          // visible={value}
          >
            {!value && placeholder}
          </InputPlaceholder>
          <TextField
            onChange={handleChange}
            value={value || ""}
            error={!!error?.status}
          />
        </InputContainer>
      );
    })({ ...props, ref })
);

export const InputPlaceholder = styled.div`
  align-items: center;
  display: flex;
  left: 12px;
  position: absolute;
  top: 39%;
  z-index: 1;
  font-size: 12px;
  font-family: "F1Reg";
  color: #999999;
`;

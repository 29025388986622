import { Modal } from "Components/Modal";
import { Body1, H3, Subtitle1 } from "Components/Text";
import { truncateDetails } from "utils/useTruncate";
import { useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "Components/Button";
import { motion } from "framer-motion";

export type AthleteProps = {
  src?: string;
  name: string;
  winnerId?: string;
  grade: string;
  sport: string;
  category: string;
  school: string;
  details: string;
  isModal?: boolean;
};

export const AthleteCard = ({
  src,
  name,
  grade,
  sport,
  school,
  category,
  details,
}: AthleteProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = (val: boolean) => {
    setIsOpen(val);
  };

  return (
    <>
      <Wrapper whileHover={{ y: -7, transition: { duration: 0.3 } }}>
        <H3 style={{ fontWeight: 700, marginBottom: "-15px" }}>{category}</H3>
        <Image src={src} loading="lazy" />
        <Name>{name}</Name>
        <Info>
          <Subtitle1>{grade}</Subtitle1>
          <Subtitle1>{sport}</Subtitle1>
          <Subtitle1>{school}</Subtitle1>
          <Subtitle1>class {category}</Subtitle1>
        </Info>
        <Details>
          <Body1>
            {details.length < 250 ? (
              details
            ) : (
              <>
                {truncateDetails(details)}
                <OpenModal onClick={() => openModal(true)}>more...</OpenModal>
              </>
            )}
          </Body1>
        </Details>
      </Wrapper>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} key={src}>
        <Wrapper>
          <Image src={src} />
          <Name>{name}</Name>
          <Info>
            <Subtitle1>{grade}</Subtitle1>
            <Subtitle1>{sport}</Subtitle1>
            <Subtitle1>{school}</Subtitle1>
            <Subtitle1>class {category}</Subtitle1>
          </Info>
          <Details>
            <Body1>{details}</Body1>
          </Details>
          <ButtonPrimary onClick={() => setIsOpen(false)}>Close</ButtonPrimary>
        </Wrapper>
      </Modal>
    </>
  );
};
export const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  color: #020c29;
  button {
    align-self: center;
  }
`;
export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${Subtitle1} {
    font-size: 16px;
  }
`;
export const Name = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 20px;
`;
const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const Image = styled.img`
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  height: 500px;
  object-position: top center;
  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 100vw;
  }
`;
export const OpenModal = styled.span`
  text-decoration: underline;
  font-size: 15px;
  color: #1700f4;
  display: inline;
  font-weight: 600;
  cursor: pointer;
`;

import { NominationStatus } from "types/types";
import { FieldMessage, Input } from "../../Fields";
import { Timestamp } from "firebase/firestore";

export type FieldProps = {
  field?: string;
  type?: string;
  value?: any;
  error?: { status?: { error: boolean; message: string } };
  onChange: (v: any) => void;
  placeholder?: React.ReactNode;
  maxLength?: number;
  fieldName?: string;
  success?: boolean;
  options?: { id: number; title: string | undefined }[];
  disabled?: boolean;
};

export const TextField = ({
  onChange,
  value,
  fieldName,
  type,
  placeholder,
  disabled,
  error,
}: FieldProps) => {
  const handleText = (value: string) => {
    onChange(value);
  };
  return (
    <div style={{ position: "relative" }}>
      <Input
        placeholder={placeholder}
        fieldName={fieldName}
        onChange={(value: string) => handleText(value)}
        value={value}
        error={error}
        disabled={disabled}
        type={type}
      />
      <FieldMessage
        fieldName={fieldName}
        active={!!value || !!error?.status}
        type={error?.status ? "error" : "success"}
        message={error?.status?.message}
      />
    </div>
  );
};

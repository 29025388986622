import styled, { css } from "styled-components";

type MessageProps = {
  type?: string;
  active?: boolean;
  message?: string;
  fieldName?: string;
};

export const FieldMessage = ({
  type,
  active = true,
  fieldName,
  message,
}: MessageProps) => {
  return active ? (
    <ErrorMessageContainer textArea={fieldName === "nomineeReason"}>
      {type === "error" && (
        <>
          <Message>{message ? message : "This field is required"}</Message>
        </>
      )}
    </ErrorMessageContainer>
  ) : null;
};

const ErrorMessageContainer = styled.div<{ textArea?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 6px;
  position: absolute;
  right: 0;
  bottom: -45%;
  height: 15px;
  ${({ textArea }) =>
    textArea &&
    css`
      bottom: -18%;
    `}
`;

const Message = styled.p`
  color: #e45c52;
  font-size: 10px;
  margin: 0;
`;

import React, { useState } from "react";
import styled from "styled-components";
import {
  NominationDocument,
  NominationStatus,
  WinnerDocument,
  YearWinnersDocument,
} from "types/types";
import { truncateDetails } from "utils/useTruncate";
import { Checkbox } from "Components/RegistrationForm/Fields/Field/Checkbox";
import { H2, H3, Subtitle1, Body1, Label, Body2 } from "Components/Text";
import { Modal } from "Components/Modal";
import { ButtonPrimary } from "Components/Button";
import { AnimatePresence, motion } from "framer-motion";
import { Field } from "Components/RegistrationForm/Fields";
import { DateTime } from "luxon";
import {
  QuerySnapshot,
  Timestamp,
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { useMonthList } from "utils/useTimelineList";
import { firebaseApp } from "Components/Firebase";
import { WinnerModal } from "Components/WinnerModal";
import { dropInList } from "Assets/Animate";

type ListItemProps = Partial<NominationDocument> &
  Partial<WinnerDocument> & {
    modalData?: NominationDocument;
    winnerData?: WinnerDocument | YearWinnersDocument;
    winnerView?: boolean;
    setSelectIds?: (v: string[]) => void;
    selectIds?: string[];
    docId?: string;
    winnerId?: string;
    docData?: QuerySnapshot<NominationDocument>;
    yearly?: boolean;
  };

export const ListItem = ({
  athleteName,
  athleteSchool,
  athleteClassification,
  athleteSport,
  athleteGrade,
  docId,
  winnerId,
  modalData,
  winnerData,
  submittedAt,
  winnerView,
  setSelectIds,
  selectIds,
  docData,
  yearly,
}: ListItemProps) => {
  const db = getFirestore(firebaseApp);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWinnerModal, setIsOpenWinnerModal] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [status, setStatus] = useState<NominationStatus | null>(null);
  const [featuredDate, setFeaturedDate] = useState<string>("");
  const dateOptions = useMonthList();

  // const ref = query(
  //   collection(db, "winners")
  // ) as CollectionReference<AthleteData>;
  // const [dataSnapshot, loading, error] = useCollection(ref);
  // console.log("modalData", modalData);

  const selectAthletes = (id: string) => {
    let list = [...selectIds!] || [];
    if (list.includes(id)) {
      let update = list.filter((v) => {
        return v !== id;
      });
      setSelectIds && setSelectIds(update);
    } else {
      list.push(id);
      setSelectIds && setSelectIds(list);
    }
  };

  const updateStatusModal = async (
    snap: QuerySnapshot<NominationDocument> | undefined,
    status: NominationStatus,
    id: string,
  ) => {
    snap?.docs.map(async (nominationDoc) => {
      if (nominationDoc?.id === id) {
        if (status === "winner" && featuredDate) {
          const updatedDate = DateTime.fromFormat(featuredDate, "LLLL yyyy");
          const winnerId =
            updatedDate.toFormat("yy-MM") +
            "-" +
            (nominationDoc.data().nomineeClassification === "Private"
              ? "PR"
              : nominationDoc.data().nomineeClassification);

          const newWinner = {
            athleteName: nominationDoc.data().nomineeName,
            athleteSchool: nominationDoc.data().nomineeSchool,
            athleteGrade: nominationDoc.data().nomineeGrade,
            athleteClassification: nominationDoc.data().nomineeClassification,
            athleteSport: nominationDoc.data().nomineeSport,
            // athletePhoto?: string;
            // athleteProfile?: nominationDoc.data().;
            nominatorRole: nominationDoc.data().nominatorRole,
            nominationId: nominationDoc.id,
            status: "winner",
            featuredDate: Timestamp.fromDate(updatedDate.toJSDate()),
          };
          await setDoc(doc(db, "winners", winnerId), { ...newWinner });
          await setDoc(
            nominationDoc.ref,
            {
              status: "winner",
            },
            { merge: true },
          );
        } else {
          await setDoc(
            nominationDoc.ref,
            {
              status: status,
            },
            { merge: true },
          );
        }
      }
    });
  };

  return (
    <>
      <AnimatePresence>
        <ListWrapper
          key={docId}
          as={motion.div}
          variants={dropInList}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div>
            {!winnerView && (
              <Checkbox
                checked={checked}
                title={athleteName || ""}
                onChange={() => {
                  setChecked(!checked);
                  selectAthletes(docId || "");
                }}
              />
            )}
          </div>
          <div>
            {" "}
            {winnerView ? (
              <Subtitle1
                style={{
                  color: "#1700F4",
                  fontWeight: 600,
                  cursor: "pointer",
                  lineHeight: "120%",
                  marginTop: "-2px",
                }}
                onClick={() => setIsOpenWinnerModal(true)}
              >
                {athleteName || ""}
              </Subtitle1>
            ) : (
              athleteName || ""
            )}
          </div>
          <div>
            {(athleteSchool || "")?.length > 25
              ? truncateDetails(athleteSchool || "", 25) + "..."
              : athleteSchool}
          </div>
          <div>{athleteClassification || ""}</div>
          <div>{athleteGrade || ""}</div>
          <div>
            {" "}
            {(athleteSport || "")?.length > 20
              ? truncateDetails(athleteSport || "", 20) + "..."
              : athleteSport}
          </div>
          {/* <div>
          {!docId && winnerView ? (
            <Body2 style={{ color: "#999" }}>N/A</Body2>
          ) : (nominatorRole || "")?.length > 15 ? (
            truncateDetails(nominatorRole || "", 15) + "..."
          ) : (
            nominatorRole
          )}
        </div>
        <div>
          {!docId && winnerView ? (
            <Body2 style={{ color: "#999" }}>N/A</Body2>
          ) : (
            <Subtitle1
              onClick={() => setIsOpen(true)}
              style={{
                color: "#1700F4",
                fontWeight: 700,
                cursor: "pointer",
                lineHeight: "130%",
              }}
            >
              View
            </Subtitle1>
          )}
        </div> */}
          {/* <div>{submittedAt?.toDate().toLocaleDateString() || "10/12/23"}</div> */}
        </ListWrapper>
      </AnimatePresence>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} key={docId}>
        <H2 style={{ color: "#112B4D" }}>Nomination</H2>
        <Wrapper>
          <H3>Athlete’s Information</H3>
          <Label disabled>Nominee Name</Label>
          <Subtitle1>{modalData?.nomineeName}</Subtitle1>
          <Label disabled>Nominee School</Label>
          <Subtitle1>{modalData?.nomineeSchool}</Subtitle1>
          <Label disabled>Nominee Classification</Label>
          <Subtitle1>class {modalData?.nomineeClassification}</Subtitle1>
          <Label disabled>Nominee Sport</Label>
          <Subtitle1>{modalData?.nomineeSport}</Subtitle1>
          <Label disabled>Nominee Coach</Label>
          <Subtitle1>{modalData?.coachName}</Subtitle1>
          {modalData?.nomineeStats && (
            <>
              <Label disabled>Nominee Stats</Label>
              <Subtitle1>{modalData?.nomineeStats}</Subtitle1>
            </>
          )}
          <Label disabled>Reason for Nomination</Label>
          <Subtitle1>{modalData?.nomineeReason}</Subtitle1>
          <Label disabled>Parent Contact Info of Nominee</Label>
          <Subtitle1>{modalData?.parentInfo}</Subtitle1>
          <H3>Nominator Information</H3>
          <Label disabled>Nominator Name</Label>
          <Subtitle1>{modalData?.nominatorName}</Subtitle1>
          <Label disabled>Nominator Role</Label>
          <Subtitle1>{modalData?.nominatorRole}</Subtitle1>
          <Label disabled>Nominator Email</Label>
          <Subtitle1>{modalData?.nominatorEmail}</Subtitle1>
          <Label disabled>Nominator Phone</Label>
          <Subtitle1>{modalData?.nominatorPhone}</Subtitle1>
          <Label disabled>Select Status</Label>
          <Field
            type="select"
            onChange={(value: NominationStatus) => setStatus(value)}
            options={[
              { id: 1, title: "winner" },
              { id: 2, title: "consideration" },
              { id: 3, title: "archived" },
            ]}
            placeholder="Select"
            value={status || modalData?.status}
          />
          {status === "winner" && (
            <>
              <Label disabled>Featured in (Year/Month)</Label>
              <Field
                type="select"
                onChange={(value: string) => setFeaturedDate(value)}
                options={dateOptions}
                placeholder="Select"
                value={featuredDate}
              />
            </>
          )}
          <ButtonPrimary
            style={{ marginTop: 30 }}
            onClick={() => {
              if (status) {
                updateStatusModal(docData, status, docId || "");
              }
              setIsOpen(false);
            }}
            disabled={
              !status ? true : status === "winner" ? !featuredDate : false
            }
          >
            Save Status
          </ButtonPrimary>
          <ButtonPrimary
            text={true}
            onClick={() => {
              setStatus(null);
              setFeaturedDate("");
              setIsOpen(false);
            }}
          >
            Close
          </ButtonPrimary>
        </Wrapper>
      </Modal>
      {isOpenWinnerModal && (
        <WinnerModal
          setIsOpen={setIsOpenWinnerModal}
          isOpen={isOpenWinnerModal}
          winnerData={winnerData}
          winnerYearData={winnerData}
          yearly={yearly}
        />
      )}
    </>
  );
};
const ListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1.1fr 1.3fr 0.8fr 1fr 1fr;
  padding: 8px 0;
  overflow: hidden !important;
  & > div {
    font-size: 14px;
    font-weight: 500;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    & > div {
      font-size: 12px;
      font-weight: 500;
    }
  }
`;
export const Wrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #112b4d;
  button {
    align-self: center;
  }
  ${Label} {
    margin-top: 20px;
    margin-bottom: 2px;
  }
  ${H3} {
    margin-top: 30px;
    color: #112b4d;
  }
`;

import React, { useState, useEffect, Fragment } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Icon } from "Assets/Svgs/caret.svg";
// import { Footnote, Icon, TextField } from "notes";

const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 16px;
  height: 16px;
`;

export const Caret = styled(Icon)`
  position: absolute;
  right: 11px;
  top: 15px;
  width: 12px;
  height: 12px;
  z-index: 1;
  path {
    fill: #333;
  }
`;

export const Dropdown = styled.div<{ open?: boolean }>`
  position: absolute;
  display: block;
  top: 100%;
  left: 0px;
  right: 0px;
  background: white;
  border-width: 1px;
  border-style: solid;
  border-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  max-height: 224px;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
  pointer-events: none;
  z-index: 3;
  ${(props) =>
    props.open &&
    `opacity: 1;
    visibility: visible;
    pointer-events: all;`}
`;

export const Item = styled.div<{ selected?: boolean }>`
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-color: #dedede;
  border-bottom-width: 1px;
  box-sizing: border-box;
  padding: 15px 10px;
  color: black;
  cursor: pointer;
  display: flex;
  flex-flow: column wrap;
  line-height: 18px;
  position: relative;
  font-size: 15px;
  &:hover {
    cursor: pointer;
    background-color: #f2f2f2;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  ${({ selected }) =>
    selected &&
    css`
      color: #999;
      pointer-events: none;
      cursor: none;
    `}
`;

// const SubTitle = styled(Footnote)`
//   display: block;
//   margin-top: 4px;
//   width: 100%;
// `;

const Selected = styled.span<{ placeholderValue?: boolean }>`
  align-items: center;
  font-family: "F1Reg";
  color: ${(props) => (props.placeholderValue ? "#ccc" : "#333333 !important")};
  display: block;
  font-size: ${(props) => (props.placeholderValue ? "12px" : "17px")};
  font-weight: ${(props) => (props.placeholderValue ? 300 : 500)};
  justify-content: space-between;
  line-height: 22px;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type SelectProps = {
  options: { id: number; title: string | undefined }[];
  placeholder: React.ReactNode;
  selected: { id: number; title: string | undefined };
  onChange: (v: string) => void;
  onOpen?: (v: boolean) => void;
  error?: { status?: { error: boolean; message: string } };
};
export const Select2 = ({
  options = [],
  placeholder,
  selected,
  onChange,
  onOpen,
  error,
  ...props
}: SelectProps) => {
  const [openState, setOpenState] = useState(false);
  const [fieldRef, setFieldRef] = useState<HTMLDivElement | null>(null);

  const handleOpenState = (val: boolean) => {
    setOpenState(val);
    !!onOpen && onOpen(val);
  };
  const handleClickOutside = (e: MouseEvent) => {
    if (!fieldRef?.contains(e.target as Node)) {
      handleOpenState(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <FieldWrapper
      error={!!error?.status}
      ref={(component) => setFieldRef(component)}
    >
      <SelectField
        open={openState}
        {...props}
        onClick={() => handleOpenState(!openState)}
      >
        <Selected placeholderValue={selected?.id ? false : true}>
          <Fragment>{selected?.id ? selected?.title : placeholder}</Fragment>
        </Selected>
        <Caret name="Dropdown" />
      </SelectField>
      <Dropdown open={openState}>
        {options.map((option: { id: number; title: string | undefined }) => {
          const { title, id } = option;
          return (
            <Item
              key={id}
              selected={id === selected?.id}
              onClick={() => {
                onChange(option?.title || "");
                handleOpenState(false);
              }}
              //   paddingChange={!!image}
              //   twoLine={!!subtitle}
            >
              <Fragment>{title}</Fragment>
            </Item>
          );
        })}
      </Dropdown>
    </FieldWrapper>
  );
};

export const FieldWrapper = styled.div<{ error?: boolean }>`
  background: transparent;
  border-radius: 6px;
  border: 1px solid #cccccc;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.error &&
    `
    background-color: white;
    border: 1px solid #E45C52;
    
  `};
`;
const SelectField = styled.div.attrs(() => ({ as: "div" }))<{
  open?: boolean;
  error?: boolean;
}>`
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 1px;
  padding-right: 30px;
  padding-left: 12px;
  position: relative;
  z-index: 1;
  ${(props) =>
    props.open &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
    z-index: 4;
    &::after {
      content: "";
      background-color: white;
      position: absolute;
      top: calc(100%);
      left: 0;
      width: 100%;
      height: 1px;
      z-index: 5;
    }
  `};
  &:focus {
    box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.24);
  }

  ${(props) =>
    props.error &&
    `
    background-color: white;
    ${IconContainer} {
      top: 9px;
      left: 9px;
    }

    `};
`;
